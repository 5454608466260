import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

// Modules
import app from './app'
import auth from './auth'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'
import adminProvider from './admin-provider'
import dashboard from './dashboard'
import admin from './admin'
import investor from './investor'
import assignor from './assignor'
import provider from './provider'
import publicStore from './public'
import common from './common'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    auth,
    admin,
    common,
    provider,
    assignor,
    investor,
    dashboard,
    appConfig,
    publicStore,
    verticalMenu,
    adminProvider,
  },
  strict: process.env.DEV,
  plugins: [createPersistedState()],
})
