import jwtDefaultConfig from './jwtDefaultConfig'
import Vue from 'vue'
import { initialAbility } from '@/libs/acl/config'

export default class JwtService {
  // Will be used by this service for making API calls
  axiosIns = null

  // jwtConfig <= Will be used by this service
  jwtConfig = { ...jwtDefaultConfig }

  // For Refreshing Token
  isAlreadyFetchingAccessToken = false

  // For Refreshing Token
  subscribers = []

  constructor(axiosIns, jwtOverrideConfig) {
    this.axiosIns = axiosIns
    this.jwtConfig = { ...this.jwtConfig, ...jwtOverrideConfig }

    // Request Interceptor
    this.axiosIns.interceptors.request.use(
      (config) => {
        // Get token from localStorage
        const accessToken = this.getToken()

        // If token is present add it to request's Authorization Header
        if (accessToken) {
          // eslint-disable-next-line no-param-reassign
          config.headers.Authorization = `${this.jwtConfig.tokenType} ${accessToken}`
        }
        return config
      },
      (error) => Promise.reject(error),
    )

    // Add request/response interceptor
    this.axiosIns.interceptors.response.use(
      (response) => response,
      (error) => {
        // const { config, response: { status } } = error
        const { config, response } = error
        const originalRequest = config

        if (response && response.status === 401 && (this.getToken() !== undefined && this.getToken() !== null)) {
          Vue.prototype
            .$swal({
              title: 'A sua sessão expirou',
              text: 'Faça um novo login para continuar utilizando a plataforma.',
              icon: 'warning',
            })
            .then(async (result) => {
              console.log(result)
              await this.logout().then(() => {
                window.location.replace('/login')
              })
            })
        } else if(response && response.status === 401) {
          Vue.prototype
            .$swal({
              title: 'Usuário ou senha incorreta',
              text: 'Tente novamente com usuario e senha corretos ou entre em contato com os administradores.',
              icon: 'error',
            })
        }

        return Promise.reject(error)
      },
    )
  }

  onAccessTokenFetched(accessToken) {
    this.subscribers = this.subscribers.filter((callback) => callback(accessToken))
  }

  addSubscriber(callback) {
    this.subscribers.push(callback)
  }

  getToken() {
    return localStorage.getItem(this.jwtConfig.storageTokenKeyName)
  }

  getRefreshToken() {
    return localStorage.getItem(this.jwtConfig.storageRefreshTokenKeyName)
  }

  setToken(value) {
    localStorage.setItem(this.jwtConfig.storageTokenKeyName, value)
  }

  setRefreshToken(value) {
    localStorage.setItem(this.jwtConfig.storageRefreshTokenKeyName, value)
  }

  removeToken() {
    localStorage.removeItem(this.jwtConfig.storageTokenKeyName)
  }

  removeRefreshToken() {
    localStorage.removeItem(this.jwtConfig.storageRefreshTokenKeyName)
  }

  login(...args) {
    return this.axiosIns.post(this.jwtConfig.loginEndpoint, ...args)
  }

  register(...args) {
    return this.axiosIns.post(this.jwtConfig.registerEndpoint, ...args)
  }

  refreshToken() {
    return this.axiosIns.post(this.jwtConfig.refreshEndpoint, {
      refreshToken: this.getRefreshToken(),
    })
  }

  removeUserData() {
    localStorage.removeItem(this.jwtConfig.storageUserDataKeyName)
  }

  async logout() {
    this.removeToken()
    this.removeRefreshToken()
    this.removeUserData()
    Vue.prototype.$ability.update(initialAbility)
    localStorage.removeItem('vuex')
  }
}
