export default [
  // AUTH
  {
    path: '/cedente/login',
    name: 'assignor.login',
    component: () => import('@/views/assignor/auth/pages/Login.vue'),
    meta: {
      layout: 'auth-assignor',
      resource: 'Auth',
      redirectIfLoggedIn: true,
      layoutPage: {
        formCard: {
          title: 'Cedente',
          icon: require('@/assets/images/assignor/login-icon.png'),
        },
        appLogoGrande: require('@/assets/images/assignor/white-bg-cs-logo.png'),
      },
    },
  },
  {
    path: '/cedente/cadastro',
    name: 'assignor.register',
    component: () => import('@/views/assignor/auth/pages/Register.vue'),
    meta: {
      layout: 'auth-assignor',
      resource: 'Auth',
      redirectIfLoggedIn: true,
      layoutPage: {
        formCard: {
          title: 'Cedente',
          icon: require('@/assets/images/assignor/login-icon.png'),
        },
        appLogoGrande: require('@/assets/images/assignor/white-bg-cs-logo.png'),
        cardToMoney: require('@/assets/images/investidor/login-image.svg'),
      },
    },
  },
  {
    path: '/cedente/auth/nova-senha',
    name: 'change-password',
    component: () => import('@/views/assignor/pages/ChangePassword.vue'),
    meta: {
      layout: 'auth',
      resource: 'Auth',
      redirectIfLoggedIn: true,
      layoutPage: {
        formCard: {
          title: 'Cedente',
          icon: require('@/assets/images/assignor/login-icon.png'),
        },
        appLogoGrande: require('@/assets/images/assignor/cs-logo-login.svg'),
        cardToMoney: require('@/assets/images/assignor/login-image.svg'),
      },
    },
  },
  {
    path: '/cedente/contato',
    name: 'assignor.contact',
    component: () => import('@/views/assignor/auth/pages/Contact.vue'),
    meta: {
      layout: 'auth-assignor',
      resource: 'Auth',
      redirectIfLoggedIn: true,
      layoutPage: {
        appLogoGrande: require('@/assets/images/assignor/white-bg-cs-logo.png'),
      },
    },
  },
  // DASH
  {
    path: '/cedente/emissoes',
    name: 'assignor.emissions',
    component: () => import('@/views/assignor/pages/Emissoes.vue'),
    meta: {
      action: 'assginor-read',
      resource: 'assginor-user',
      pageTitle: 'Minhas emissões',
      pageSubtitle: 'Acompanhe abaixo todas as suas emissões.',
      skipGoToHome: true,
      showWhatsAppAction: true,
      indexObject: {},
    },
  },
  {
    path: '/cedente/emissoes/:serie_id/detalhes',
    name: 'assignor.details',
    component: () => import('@/views/assignor/pages/Detalhes.vue'),
    meta: {
      action: 'assginor-read',
      resource: 'assginor-user',
      pageTitle: 'Detalhamento da emissão',
      pageSubtitle: 'Análise detalhada da emissão.',
      skipGoToHome: true,
      showWhatsAppAction: true,
    },
  },
  {
    path: '/cedente/obrigacoes',
    name: 'assignor.events',
    component: () => import('@/views/assignor/pages/Eventos.vue'),
    meta: {
      action: 'assginor-read',
      resource: 'assginor-user',
      pageTitle: 'Despesas',
      pageSubtitle: 'Acompanhe abaixo todas as suas despesas.',
      skipGoToHome: true,
      showWhatsAppAction: true,
      indexObject: {},
    },
  },
  {
    path: '/cedente/calculadora',
    name: 'assignor.calculator',
    component: () => import('@/views/assignor/pages/Calculator.vue'),
    meta: {
      action: 'assginor-read',
      resource: 'assginor-user',
    },
  },
  {
    path: '/cedente/perfil',
    name: 'assignor.profile',
    component: () => import('@/views/assignor/pages/Profile.vue'),
    meta: {
      action: 'assginor-read',
      resource: 'assginor-user',
      pageTitle: 'Perfil',
      skipGoToHome: true,
    },
  },
  {
    path: '/cedente/usuarios-e-empresas',
    name: 'assignor.users',
    component: () => import('@/views/assignor/pages/Users.vue'),
    meta: {
      action: 'assginor-read',
      resource: 'assginor-user',
      pageTitle: 'Usuários e empresas',
      skipGoToHome: true,
    },
  },
  {
    path: '/cedente/usuarios-e-empresas/',
    component: () => import('@/layouts/vertical/LayoutVerticalAssignorUser.vue'),
    meta: {
      action: 'admin-read',
      resource: 'admin-user',
    },
    children: [
      {
        path: 'usuarios',
        name: 'assignor.users.users',
        component: () => import('@/views/assignor/pages/Users.vue'),
        meta: {
          action: 'assginor-read',
          resource: 'assginor-user',
          pageTitle: 'Usuários e empresas',
          skipGoToHome: true,
        },
      },
      {
        path: 'empresas',
        name: 'assignor.users.companies',
        component: () => import('@/views/assignor/pages/Companies.vue'),
        meta: {
          action: 'assginor-read',
          resource: 'assginor-user',
          pageTitle: 'Empresas',
          pageSubtitle: 'Confira abaixo suas informações registrados na plataforma',
          skipGoToHome: true,
        },
      },
    ],
  },
  {
    path: '/cedente/notas-e-recidos',
    name: 'assignor.notes',
    component: () => import('@/views/assignor/pages/NotesAndReceipts.vue'),
    meta: {
      action: 'assginor-read',
      resource: 'assginor-user',
      pageTitle: 'Notas e recibos',
      skipGoToHome: true,
    },
  },
  {
    path: '/cedente/chaves-api',
    name: 'assignor.apiKeys',
    component: () => import('@/views/assignor/pages/ApiKeys.vue'),
    meta: {
      action: 'assginor-read',
      resource: 'assginor-user',
      pageTitle: 'Chaves de API',
      skipGoToHome: true,
    },
  },
]
