import http from '@/services/http'

class User {
  fetch() {
    return http.get('/admin/usuarios')
  }

  update(payload) {
    return http.put(`/admin/usuarios/${payload.id}`, { user: payload })
  }

  getByID(id) {
    return http.get(`/admin/usuarios/${id}`)
  }

  getUserIfB3Codes(id) {
    return http.get(`/admin/investidor/ifb3_autorizados/${id}`)
  }

  addIfB3(payload) {
    return http.post(`/admin/investidor/ifb3_autorizados/${payload.id}/add`, payload)
  }

  removeIfB3(payload) {
    return http.post(`/admin/investidor/ifb3_autorizados/${payload.id}/remove`, payload)
  }

  downloadUsersExcel(url) {
    return http.get(url, { responseType: 'arraybuffer'})
  }
}

export default new User()
