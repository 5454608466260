import Emissions from '@/services/emissions'
import Invite from '@/services/invite'
import Assignor from '@/services/assignor'
import Profile from '@/services/profile'

const fetchEmissions = ({}, payload) => {
  return new Promise((resolve, reject) => {
    const emissions = new Emissions('cedente')
    emissions
      .fetch(payload)
      .then((response) => resolve(response))
      .catch((error) => reject(error))
  })
}
const getEmission = ({}, payload) => {
  return new Promise((resolve, reject) => {
    const emissions = new Emissions('cedente')
    emissions
      .get(payload)
      .then((response) => resolve(response))
      .catch((error) => reject(error))
  })
}

const paymentHistory = ({}, payload) => {
  return new Promise((resolve, reject) => {
    const emissions = new Emissions('cedente')
    emissions
      .paymentHistory(payload)
      .then((response) => resolve(response))
      .catch((error) => reject(error))
  })
}

const pusGraph = ({}, payload) => {
  return new Promise((resolve, reject) => {
    const emissions = new Emissions('cedente')
    emissions
      .pusGraph(payload)
      .then((response) => resolve(response))
      .catch((error) => reject(error))
  })
}

const pus = ({}, payload) => {
  return new Promise((resolve, reject) => {
    const emissions = new Emissions('cedente')
    emissions
      .pus(payload)
      .then((response) => resolve(response))
      .catch((error) => reject(error))
  })
}

const downloadPusHistory = ({}, payload) => {
  return new Promise((resolve, reject) => {
    const emissions = new Emissions('cedente')
    emissions
      .downloadPusHistory(payload)
      .then((response) => resolve(response))
      .catch((error) => reject(error))
  })
}

const calcDesagio = ({}, payload) => {
  return new Promise((resolve, reject) => {
    const emissions = new Emissions('cedente')
    emissions
      .calcDesagio(payload)
      .then((response) => resolve(response))
      .catch((error) => reject(error))
  })
}

const getDocs = ({}, payload) => {
  return new Promise((resolve, reject) => {
    const emissions = new Emissions('cedente')
    emissions
      .getDocs(payload)
      .then((response) => resolve(response))
      .catch((error) => reject(error))
  })
}

const validateInvite = ({}, payload) => {
  return new Promise((resolve, reject) => {
    const invite = new Invite('cedente')
    invite
      .validate(payload)
      .then((response) => resolve(response))
      .catch((error) => reject(error))
  })
}

const acceptInvite = ({}, payload) => {
  return new Promise((resolve, reject) => {
    const invite = new Invite('cedente')
    invite
      .accept(payload)
      .then((response) => resolve(response))
      .catch((error) => reject(error))
  })
}

const currentEconomicGroup = ({}, payload) => {
  return new Promise((resolve, reject) => {
    Assignor.getCurrentEconomicGroup(payload)
      .then((response) => resolve(response))
      .catch((error) => reject(error))
  })
}

const updateProfile = ({}, payload) => {
  return new Promise((resolve, reject) => {
    Profile.updateAssignorProfile(payload)
      .then((response) => resolve(response))
      .catch((error) => reject(error))
  })
}

const createInvite = ({}) => {
  return new Promise((resolve, reject) => {
    const invite = new Invite('cedente')
    invite
      .create()
      .then((response) => resolve(response))
      .catch((error) => reject(error))
  })
}

const fetchInvites = ({}) => {
  return new Promise((resolve, reject) => {
    const invite = new Invite('cedente')
    invite
      .invites()
      .then((response) => resolve(response))
      .catch((error) => reject(error))
  })
}

const addInvite = ({}, payload) => {
  return new Promise((resolve, reject) => {
    const invite = new Invite('cedente')
    invite
      .addInvite(payload)
      .then((response) => resolve(response))
      .catch((error) => reject(error))
  })
}

const deleteInvite = ({}, payload) => {
  return new Promise((resolve, reject) => {
    const invite = new Invite('cedente')
    invite
      .deleteInvite(payload)
      .then((response) => resolve(response))
      .catch((error) => reject(error))
  })
}

const sendInvite = ({}, payload) => {
  return new Promise((resolve, reject) => {
    const invite = new Invite('cedente')
    invite
      .sendInvite(payload)
      .then((response) => resolve(response))
      .catch((error) => reject(error))
  })
}

const calcDesagioCetip = ({}, payload) => {
  return new Promise((resolve, reject) => {
    const emissions = new Emissions('cedente')
    emissions
      .calcDesagioCetip(payload)
      .then((response) => resolve(response))
      .catch((error) => reject(error))
  })
}

export default {
  pus,
  getDocs,
  pusGraph,
  addInvite,
  sendInvite,
  getEmission,
  calcDesagio,
  createInvite,
  deleteInvite,
  fetchInvites,
  acceptInvite,
  updateProfile,
  fetchEmissions,
  paymentHistory,
  validateInvite,
  calcDesagioCetip,
  downloadPusHistory,
  currentEconomicGroup,
}
