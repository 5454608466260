export default [
  // AUTH
  {
    path: '/prestadordeservico/login',
    name: 'provider.login',
    component: () => import('@/views/provider/auth/pages/Login.vue'),
    meta: {
      layout: 'auth-provider',
      resource: 'Auth',
      redirectIfLoggedIn: true,
      layoutPage: {
        formCard: {
          title: 'Prestador de serviço',
          icon: require('@/assets/images/provider/login-icon.svg'),
        },
        appLogoGrande: require('@/assets/images/provider/dark-bg-cs-logo.png'),
      },
    },
  },
  {
    path: '/prestadordeservico/cadastro',
    name: 'provider.register',
    component: () => import('@/views/provider/auth/pages/Register.vue'),
    meta: {
      layout: 'auth-provider',
      resource: 'Auth',
      redirectIfLoggedIn: true,
      layoutPage: {
        formCard: {
          title: 'Prestador de serviço',
          icon: require('@/assets/images/provider/login-icon.png'),
        },
        appLogoGrande: require('@/assets/images/provider/white-bg-cs-logo-fundo-escuro.png'),
        cardToMoney: require('@/assets/images/investidor/login-image.svg'),
      },
    },
  },
  {
    path: '/prestadordeservico/auth/nova-senha',
    name: 'change-password',
    component: () => import('@/views/provider/pages/ChangePassword.vue'),
    meta: {
      layout: 'auth',
      resource: 'Auth',
      redirectIfLoggedIn: true,
      layoutPage: {
        formCard: {
          title: 'Prestador de Serviço',
          icon: require('@/assets/images/provider/login-icon.svg'),
        },
        appLogoGrande: require('@/assets/images/provider/cs-logo-login.svg'),
        cardToMoney: require('@/assets/images/provider/login-image.svg'),
      },
    },
  },
  {
    path: '/prestadordeservico/contato',
    name: 'provider.contact',
    component: () => import('@/views/provider/auth/pages/Contact.vue'),
    meta: {
      layout: 'auth-provider',
      resource: 'Auth',
      redirectIfLoggedIn: true,
      layoutPage: {
        appLogoGrande: require('@/assets/images/provider/white-bg-cs-logo.png'),
      },
    },
  },
  // DASH
  {
    path: '/prestadordeservico/emissoes',
    name: 'provider.emissions',
    component: () => import('@/views/provider/pages/Emissoes.vue'),
    meta: {
      action: 'provider-read',
      resource: 'provider-user',
      pageTitle: 'Operações vinculadas',
      pageSubtitle: 'Acompanhe abaixo todas as operações vinculadas à sua conta',
      skipGoToHome: true,
      showWhatsAppAction: true,
      indexObject: {},
    },
  },
  {
    path: '/prestadordeservico/emissoes/:serie_id/detalhes',
    name: 'provider.details',
    component: () => import('@/views/provider/pages/Detalhes.vue'),
    meta: {
      action: 'provider-read',
      resource: 'provider-user',
      pageTitle: 'Detalhamento da emissão',
      pageSubtitle: 'Análise detalhada da emissão.',
      skipGoToHome: true,
      showWhatsAppAction: true,
    },
  },
  {
    path: '/prestadordeservico/perfil',
    name: 'provider.profile',
    component: () => import('@/views/provider/pages/Profile.vue'),
    meta: {
      action: 'provider-read',
      resource: 'provider-user',
      pageTitle: 'Perfil',
      skipGoToHome: true,
    },
  },
  {
    path: '/prestadordeservico/usuarios-e-empresas/',
    component: () => import('@/layouts/vertical/LayoutVerticalProviderUser.vue'),
    meta: {
      action: 'provider-read',
      resource: 'provider-user',
    },
    children: [
      {
        path: 'usuarios',
        name: 'provider.users.users',
        component: () => import('@/views/provider/pages/Users.vue'),
        meta: {
          action: 'provider-read',
          resource: 'provider-user',
          pageTitle: 'Usuários e empresas',
          skipGoToHome: true,
        },
      },
      {
        path: 'empresas',
        name: 'provider.users.companies',
        component: () => import('@/views/provider/pages/Companies.vue'),
        meta: {
          action: 'provider-read',
          resource: 'provider-user',
          pageTitle: 'Usuários e empresas',
          skipGoToHome: true,
        },
      },
    ],
  },
]
