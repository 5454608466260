import http from './http'

class Invite {
  constructor(api_sub_path = 'admin/cedente') {
    this.api_sub_path = api_sub_path
  }

  invites(payload) {
    let key = ''

    if (this.api_sub_path == 'admin/cedente') {
      key = 'cedente_grupo_economico_id'
    } else if (this.api_sub_path == 'admin/fornecedor') {
      key = 'fornecedor_grupo_economico_id'
    }

    if(!!payload?.id){ 
      payload[key] = payload[id]
      delete payload.id
    }

    return http.get(`/${this.api_sub_path}/convites`, { params: payload })
  }

  addInvite(payload) {
    return http.post(`/${this.api_sub_path}/convites`, { convite: payload })
  }

  addInvestorInvite(payload) {
    return http.post(`/${this.api_sub_path}/convites`, { convite: payload })
  }

  deleteInvite(id) {
    return http.delete(`/${this.api_sub_path}/convites/${id}`)
  }

  sendInvite(id) {
    return http.post(`/${this.api_sub_path}/convites/${id}/enviar`)
  }
  validate(token) {
    return http.get(`/${this.api_sub_path}/convites/validar/${token}`)
  }

  accept(payload) {
    return http.post(`/${this.api_sub_path}/convites/aceitar`, { user: payload })
  }
}

export default Invite
