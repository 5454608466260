import Invite from '@/services/invite'
import EconomicGroup from '@/services/economic_group'

const block = ({}, payload) => {
  return new Promise((resolve, reject) => {
    const ec = new EconomicGroup('fornecedor')
    ec.block(payload)
      .then((response) => resolve(response))
      .catch((error) => reject(error))
  })
}
const unblock = ({}, payload) => {
  return new Promise((resolve, reject) => {
    const ec = new EconomicGroup('fornecedor')
    ec.unblock(payload)
      .then((response) => resolve(response))
      .catch((error) => reject(error))
  })
}

const createEconomicGroup = ({}, payload) => {
  return new Promise((resolve, reject) => {
    const ec = new EconomicGroup('fornecedor')
    ec.create(payload)
      .then((response) => resolve(response))
      .catch((error) => reject(error))
  })
}
const getEconomicGroup = ({}, payload) => {
  return new Promise((resolve, reject) => {
    const ec = new EconomicGroup('fornecedor')
    ec.get(payload)
      .then((response) => resolve(response))
      .catch((error) => reject(error))
  })
}
const addProvider = ({}, payload) => {
  return new Promise((resolve, reject) => {
    const ec = new EconomicGroup('fornecedor')
    ec.addProvider(payload)
      .then((response) => resolve(response))
      .catch((error) => reject(error))
  })
}
const deleteProvider = ({}, payload) => {
  return new Promise((resolve, reject) => {
    const ec = new EconomicGroup('fornecedor')
    ec.deleteProvider(payload)
      .then((response) => resolve(response))
      .catch((error) => reject(error))
  })
}

const createInvite = ({}) => {
  return new Promise((resolve, reject) => {
    const invite = new Invite('admin/fornecedor')
    invite
      .create()
      .then((response) => resolve(response))
      .catch((error) => reject(error))
  })
}

const fetchInvites = ({}, payload) => {
  return new Promise((resolve, reject) => {
    const invite = new Invite('admin/fornecedor')
    invite
      .invites(payload)
      .then((response) => resolve(response))
      .catch((error) => reject(error))
  })
}

const addInvite = ({}, payload) => {
  return new Promise((resolve, reject) => {
    const invite = new Invite('admin/fornecedor')
    invite
      .addInvite(payload)
      .then((response) => resolve(response))
      .catch((error) => reject(error))
  })
}

const deleteInvite = ({}, payload) => {
  return new Promise((resolve, reject) => {
    const invite = new Invite('admin/fornecedor')
    invite
      .deleteInvite(payload)
      .then((response) => resolve(response))
      .catch((error) => reject(error))
  })
}

const sendInvite = ({}, payload) => {
  return new Promise((resolve, reject) => {
    const invite = new Invite('admin/fornecedor')
    invite
      .sendInvite(payload)
      .then((response) => resolve(response))
      .catch((error) => reject(error))
  })
}

export default {
  block,
  unblock,
  addInvite,
  addProvider,
  fetchInvites,
  deleteInvite,
  sendInvite,
  createInvite,
  deleteProvider,
  getEconomicGroup,
  createEconomicGroup,
}
