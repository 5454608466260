export default [
  {
    path: '/emissoes',
    name: 'emissoes',
    component: () => import('@/views/public/Emissoes.vue'),
    meta: {
      layout: 'public',
      title: 'Emissões',
      subtitle: 'Acompanhe abaixo todas as nossas emissões',
    },
  },
  {
    path: '/emissoes/:serie_id/detalhes',
    name: 'detalhes',
    component: () => import('@/views/public/Detalhes.vue'),
    meta: {
      layout: 'public',
      title: 'Detalhamento da emissão',
      subtitle: 'Análise detalhada da emissão.',
      showWhatsAppAction: true,
    },
  },
  {
    path: '/precos-unitarios',
    name: 'precosUnitarios',
    component: () => import('@/views/public/Pus.vue'),
    meta: {
      layout: 'public',
      title: 'Preços unitários',
      subtitle:
        'Os valores divulgados são meramente indicativos, provenientes da nossa interpretação do Termo de Securitização não havendo garantia de exatidão, não nos responsabilizamos por erros de avaliação. Destacamos que estes números estão sujeitos a alterações a qualquer momento.',
    },
  },
]
