import store from '@/store'
import Invite from '@/services/invite'
import User from '@/services/user'
import EconomicGroup from '@/services/economic_group'
import Akrual from '@/services/akrual'

const block = ({}, payload) => {
  return new Promise((resolve, reject) => {
    const ec = new EconomicGroup()
    ec.block(payload)
      .then((response) => resolve(response))
      .catch((error) => reject(error))
  })
}
const unblock = ({}, payload) => {
  return new Promise((resolve, reject) => {
    const ec = new EconomicGroup()
    ec.unblock(payload)
      .then((response) => resolve(response))
      .catch((error) => reject(error))
  })
}

const fetchUser = ({}) => {
  return new Promise((resolve, reject) => {
    User.fetch()
      .then((response) => resolve(response))
      .catch((error) => reject(error))
  })
}

const updateUser = ({}, payload) => {
  return new Promise((resolve, reject) => {
    User.update(payload)
      .then((response) => resolve(response))
      .catch((error) => reject(error))
  })
}

const getUser = ({}, payload) => {
  return new Promise((resolve, reject) => {
    User.getByID(payload)
      .then((response) => resolve(response))
      .catch((error) => reject(error))
  })
}

const getUserIfB3Codes = ({}, payload) => {
  return new Promise((resolve, reject) => {
    User.getUserIfB3Codes(payload)
      .then((response) => resolve(response))
      .catch((error) => reject(error))
  })
}

const createEconomicGroup = ({}, payload) => {
  return new Promise((resolve, reject) => {
    const ec = new EconomicGroup()
    ec.create(payload)
      .then((response) => resolve(response))
      .catch((error) => reject(error))
  })
}
const getEconomicGroup = ({}, payload) => {
  return new Promise((resolve, reject) => {
    const ec = new EconomicGroup()
    ec.get(payload)
      .then((response) => resolve(response))
      .catch((error) => reject(error))
  })
}
const addAssignor = ({}, payload) => {
  return new Promise((resolve, reject) => {
    const ec = new EconomicGroup()
    ec.addAssignor(payload)
      .then((response) => resolve(response))
      .catch((error) => reject(error))
  })
}
const deleteAssignor = ({}, payload) => {
  return new Promise((resolve, reject) => {
    const ec = new EconomicGroup()
    ec.deleteAssignor(payload)
      .then((response) => resolve(response))
      .catch((error) => reject(error))
  })
}

const createInvite = ({}) => {
  return new Promise((resolve, reject) => {
    const invite = new Invite()
    invite
      .create()
      .then((response) => resolve(response))
      .catch((error) => reject(error))
  })
}

const fetchInvites = ({}, payload) => {
  return new Promise((resolve, reject) => {
    const invite = new Invite()
    invite
      .invites(payload)
      .then((response) => resolve(response))
      .catch((error) => reject(error))
  })
}

const fetchInvestorInvites = ({}, payload) => {
  return new Promise((resolve, reject) => {
    const invite = new Invite('admin/investidor')
    invite
      .invites(payload)
      .then((response) => resolve(response))
      .catch((error) => reject(error))
  })
}

const deleteInvestorInvite = ({}, payload) => {
  return new Promise((resolve, reject) => {
    const invite = new Invite('admin/investidor')
    invite
      .deleteInvite(payload)
      .then((response) => resolve(response))
      .catch((error) => reject(error))
  })
}

const sendInvestorInvite = ({}, payload) => {
  return new Promise((resolve, reject) => {
    const invite = new Invite('admin/investidor')
    invite
      .sendInvite(payload)
      .then((response) => resolve(response))
      .catch((error) => reject(error))
  })
}

const addInvite = ({}, payload) => {
  return new Promise((resolve, reject) => {
    const invite = new Invite()
    invite
      .addInvite(payload)
      .then((response) => resolve(response))
      .catch((error) => reject(error))
  })
}

const addInvestorInvite = ({}, payload) => {
  return new Promise((resolve, reject) => {
    const invite = new Invite('admin/investidor')
    invite
      .addInvestorInvite(payload)
      .then((response) => resolve(response))
      .catch((error) => reject(error))
  })
}

const deleteInvite = ({}, payload) => {
  return new Promise((resolve, reject) => {
    const invite = new Invite()
    invite
      .deleteInvite(payload)
      .then((response) => resolve(response))
      .catch((error) => reject(error))
  })
}

const sendInvite = ({}, payload) => {
  return new Promise((resolve, reject) => {
    const invite = new Invite()
    invite
      .sendInvite(payload)
      .then((response) => resolve(response))
      .catch((error) => reject(error))
  })
}

const addIfB3 = ({}, payload) => {
  return new Promise((resolve, reject) => {
    User.addIfB3(payload)
      .then((response) => resolve(response))
      .catch((error) => reject(error))
  })
}

const removeIfB3 = ({}, payload) => {
  return new Promise((resolve, reject) => {
    User.removeIfB3(payload)
      .then((response) => resolve(response))
      .catch((error) => reject(error))
  })
}

const downloadUsersExcel = ({}, url) => {
  return new Promise((resolve, reject) => {
    User.downloadUsersExcel(url)
      .then((response) => resolve(response))
      .catch((error) => reject(error))
  })
}

const updateAkrualData = ({}) => {
  store.commit('admin/SET_LAST_AKRUAL_UPDATE_TIME', Date.now(), { root: true })
  return new Promise((resolve, reject) => {
    Akrual.updateData()
      .then((response) => resolve(response))
      .catch((error) => reject(error))
  })
}

const lastUpdateAkrualData = ({}) => {
  return new Promise((resolve, reject) => {
    Akrual.lastUpdateData()
      .then((response) => resolve(response))
      .catch((error) => reject(error))
  })
}

export default {
  block,
  getUser,
  addIfB3,
  unblock,
  addInvite,
  fetchUser,
  updateUser,
  sendInvite,
  removeIfB3,
  addAssignor,
  fetchInvites,
  deleteInvite,
  createInvite,
  deleteAssignor,
  getUserIfB3Codes,
  getEconomicGroup,
  addInvestorInvite,
  sendInvestorInvite,
  createEconomicGroup,
  deleteInvestorInvite,
  fetchInvestorInvites,
  downloadUsersExcel,
  updateAkrualData,
  lastUpdateAkrualData,
}
