import Emissions from '@/services/emissions'
import Invite from '@/services/invite'
import Provider from '@/services/provider'
import Profile from '@/services/profile'

const fetchEmissions = ({}, payload) => {
  return new Promise((resolve, reject) => {
    const emissions = new Emissions('fornecedor')
    emissions
      .fetch(payload)
      .then((response) => resolve(response))
      .catch((error) => reject(error))
  })
}
const getEmission = ({}, payload) => {
  return new Promise((resolve, reject) => {
    const emissions = new Emissions('fornecedor')
    emissions
      .get(payload)
      .then((response) => resolve(response))
      .catch((error) => reject(error))
  })
}

const getDocs = ({}, payload) => {
  return new Promise((resolve, reject) => {
    const emissions = new Emissions('fornecedor')
    emissions
      .getDocs(payload)
      .then((response) => resolve(response))
      .catch((error) => reject(error))
  })
}

const validateInvite = ({}, payload) => {
  return new Promise((resolve, reject) => {
    const invite = new Invite('fornecedor')
    invite
      .validate(payload)
      .then((response) => resolve(response))
      .catch((error) => reject(error))
  })
}

const acceptInvite = ({}, payload) => {
  return new Promise((resolve, reject) => {
    const invite = new Invite('fornecedor')
    invite
      .accept(payload)
      .then((response) => resolve(response))
      .catch((error) => reject(error))
  })
}

const currentEconomicGroup = ({}, payload) => {
  return new Promise((resolve, reject) => {
    Provider.getCurrentEconomicGroup(payload)
      .then((response) => resolve(response))
      .catch((error) => reject(error))
  })
}

const createInvite = ({}) => {
  return new Promise((resolve, reject) => {
    const invite = new Invite('fornecedor')
    invite
      .create()
      .then((response) => resolve(response))
      .catch((error) => reject(error))
  })
}

const fetchInvites = ({}) => {
  return new Promise((resolve, reject) => {
    const invite = new Invite('fornecedor')
    invite
      .invites()
      .then((response) => resolve(response))
      .catch((error) => reject(error))
  })
}

const addInvite = ({}, payload) => {
  return new Promise((resolve, reject) => {
    const invite = new Invite('fornecedor')
    invite
      .addInvite(payload)
      .then((response) => resolve(response))
      .catch((error) => reject(error))
  })
}

const deleteInvite = ({}, payload) => {
  return new Promise((resolve, reject) => {
    const invite = new Invite('fornecedor')
    invite
      .deleteInvite(payload)
      .then((response) => resolve(response))
      .catch((error) => reject(error))
  })
}

const sendInvite = ({}, payload) => {
  return new Promise((resolve, reject) => {
    const invite = new Invite('fornecedor')
    invite
      .sendInvite(payload)
      .then((response) => resolve(response))
      .catch((error) => reject(error))
  })
}

const updateProfile = ({}, payload) => {
  return new Promise((resolve, reject) => {
    Profile.updateProviderProfile(payload)
      .then((response) => resolve(response))
      .catch((error) => reject(error))
  })
}

export default {
  getDocs,
  addInvite,
  sendInvite,
  getEmission,
  createInvite,
  fetchInvites,
  deleteInvite,
  acceptInvite,
  updateProfile,
  fetchEmissions,
  validateInvite,
  currentEconomicGroup,
}
