import http from '@axios'
import handleErrors from '@/libs/http/handleErrors'

const interceptRequest = async (config) => {
  const token = localStorage.getItem('accessToken')
  config.headers.common.Authorization = token
  return config
}

const interceptRequestError = (error) => Promise.reject(error)

const interceptResponse = (response) => response

const interceptResponseError = async (error) => {
  if (error.response.config.url.includes('publico/calculadora')) return Promise.reject(error)

  await handleErrors(error)

  return Promise.reject(error)
}

http.interceptors.request.use(interceptRequest, interceptRequestError)
http.interceptors.response.use(interceptResponse, interceptResponseError)

export default http
