import http from '@/services/http'

class Profile {
  updateInvestorProfile(payload) {
    return http.post('/investidor/usuarios/update_current', { user: payload })
  }

  updateAssignorProfile(payload) {
    return http.post('/cedente/usuarios/update_current', { user: payload })
  }

  updateProviderProfile(payload) {
    return http.post('/fornecedor/usuarios/update_current', { user: payload })
  }
}

export default new Profile()
