export default [
  // AUTH
  {
    path: '/investidor/login',
    name: 'investor.login',
    component: () => import('@/views/investor/auth/pages/Login.vue'),
    meta: {
      layout: 'auth',
      resource: 'Auth',
      redirectIfLoggedIn: true,
      layoutPage: {
        formCard: {
          title: 'Investidor',
          icon: require('@/assets/images/investidor/login-icon.svg'),
        },
        appLogoGrande: require('@/assets/images/investidor/cs-logo-login.svg'),
        cardToMoney: require('@/assets/images/investidor/login-image.svg'),
      },
    },
  },
  {
    path: '/investidor/cadastro',
    name: 'investor.register',
    component: () => import('@/views/investor/auth/pages/Register.vue'),
    meta: {
      layout: 'auth',
      resource: 'Auth',
      redirectIfLoggedIn: true,
      layoutPage: {
        formCard: {
          title: 'Investidor',
          icon: require('@/assets/images/investidor/login-icon.svg'),
        },
        appLogoGrande: require('@/assets/images/investidor/cs-logo-login.svg'),
        cardToMoney: require('@/assets/images/investidor/login-image.svg'),
      },
    },
  },
  {
    path: '/investidor/auth/nova-senha',
    name: 'change-password',
    component: () => import('@/views/investor/pages/ChangePassword.vue'),
    meta: {
      layout: 'auth',
      resource: 'Auth',
      redirectIfLoggedIn: true,
      layoutPage: {
        formCard: {
          title: 'Investidor',
          icon: require('@/assets/images/investidor/login-icon.svg'),
        },
        appLogoGrande: require('@/assets/images/investidor/cs-logo-login.svg'),
        cardToMoney: require('@/assets/images/investidor/login-image.svg'),
      },
    },
  },
  // DASH
  {
    path: '/investidor/emissoes',
    name: 'investor.emissions',
    component: () => import('@/views/investor/pages/Emissoes.vue'),
    meta: {
      action: 'investor-read',
      resource: 'investor-user',
      pageTitle: 'Emissões',
      pageSubtitle: 'Acompanhe abaixo todas as nossas emissões.',
      skipGoToHome: true,
      showWhatsAppAction: true,
      indexObject: {
        filters: [
          {
            category: 'Por parâmetro',
            fields: [
              {
                label: 'Tipo de emissão',
                key: 'tipo_emissao',
                type: 'select',
                placeholder: 'Todos',
                options: [
                  { label: 'CRA', value: 'CRA' },
                  { label: 'CRI', value: 'CRI' },
                  { label: 'FIDC', value: 'FIDC' },
                  { label: 'BID', value: 'BID' },
                  { label: 'Debêntures', value: 'Debêntures' },
                ],
              },
              {
                label: 'Tempo vencimento (anos)',
                key: 'vencimento_anos',
                type: 'slider',
              },
              {
                label: 'Rating',
                key: 'rating',
                type: 'select',
                placeholder: 'Todos',
                options: [{ label: 'Com rating', value: true }],
              },
            ],
          },
        ],
      },
    },
  },
  {
    path: '/investidor/emissoes/:serie_id/detalhes',
    name: 'investor.details',
    component: () => import('@/views/investor/pages/Detalhes.vue'),
    meta: {
      action: 'investor-read',
      resource: 'investor-user',
      pageTitle: 'Detalhamento da emissão',
      pageSubtitle: 'Análise detalhada da emissão.',
      skipGoToHome: true,
      showWhatsAppAction: true,
    },
  },
  {
    path: '/investidor/obrigacoes',
    name: 'investor.events',
    component: () => import('@/views/investor/pages/Eventos.vue'),
    meta: {
      action: 'investor-read',
      resource: 'investor-user',
      pageTitle: 'Despesas',
      pageSubtitle: 'Acompanhe abaixo todas as suas despesas.',
      skipGoToHome: true,
      showWhatsAppAction: true,
      indexObject: {},
    },
  },
  {
    path: '/investidor/calculadora',
    name: 'investor.calculator',
    component: () => import('@/views/investor/pages/Calculator.vue'),
    meta: {
      action: 'investor-read',
      resource: 'investor-user',
      pageTitle: 'Calculadora',
      skipGoToHome: true,
    },
  },
  {
    path: '/investidor/perfil',
    name: 'investor.profile',
    component: () => import('@/views/investor/pages/Profile.vue'),
    meta: {
      action: 'investor-read',
      resource: 'investor-user',
      pageTitle: 'Perfil',
      skipGoToHome: true,
    },
  },
]
