import http from './http'

class Akrual {
  updateData() {
    return http.post(`/admin/integracao/akrual/update_data`, {})
  }

  lastUpdateData() {
    return http.get(`/admin/integracao/akrual/last_update_info`)
  }
}

export default new Akrual()
