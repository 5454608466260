import http from '@/services/http'

class EconomicGroup {
  constructor(type = 'cedente') {
    this.type = type
  }
  fetch() {
    return http.get(`/admin/${this.type}/grupos_economicos`)
  }

  get(id) {
    return http.get(`/admin/${this.type}/grupos_economicos/${id}`)
  }

  create(payload) {
    return http.post(`/admin/${this.type}/grupos_economicos`, { grupo_economico: payload })
  }

  addAssignor(payload) {
    return http.post(`/admin/${this.type}/cedentes`, { cedente: payload })
  }

  deleteAssignor(id) {
    return http.delete(`/admin/${this.type}/cedentes/${id}`)
  }

  addProvider(payload) {
    return http.post(`/admin/${this.type}/fornecedores`, { fornecedor: payload })
  }

  deleteProvider(id) {
    return http.delete(`/admin/${this.type}/fornecedores/${id}`)
  }

  block(payload) {
    return http.post(`/admin/${this.type}/grupos_economicos/block`, payload)
  }

  unblock(payload) {
    return http.post(`/admin/${this.type}/grupos_economicos/unblock`, payload)
  }
}

export default EconomicGroup
