import Vue from 'vue'
import VueSweetalert2 from 'vue-sweetalert2'
import 'sweetalert2/dist/sweetalert2.min.css'

Vue.use(VueSweetalert2, {
  reverseButtons: true,
  confirmButtonColor: '#3FE4C6',
  customClass: {
    closeButton: 'text-dark',
    confirmButton: 'text-dark',
    denyButton: 'text-dark',
    cancelButton: 'text-dark border-success bg-white',
  },
})
